import React from 'react';

import { ALIGN, BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { Tel } from '@latitude/tel';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import { List, ListItem } from '@latitude/list';
import { Heading5 } from '@latitude/heading';

import Layout from '@/components/layout';

import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';

import {
  Tile,
  TileContainer,
  TileIcon,
  TileButtonLink,
  MediaQueryWrapper,
  FlexContainer
} from './_styled-contact-us';
import GemMicrositeHeader from './_gem-microsite-header';

const ContactUs = ({ location }) => (
  <Layout location={location}>
    <Metadata
      title={`Contact Us |  ${TITLE_SUFFIX}`}
      description="Do you have any questions or concerns? Find out all the different ways you can contact us."
      keywords="Latitude Gem Visa"
      canonical={`${SITE_URL}${location.pathname}`}
    />
    <GemMicrositeHeader />
    <Box backgroundColor={COLOR.GREY6}>
      <div
        css={`
          position: relative;
        `}
      >
        <Hero
          css={`
            .HeroContent {
              background-color: ${COLOR.WHITE};
              @media (min-width: ${BREAKPOINT.MD}) {
                background-color: transparent;
                min-height: 380px;
              }
              @media (min-width: ${BREAKPOINT.LG}) {
                br {
                  display: none;
                }
              }
            }
          `}
          title="Something on your mind? Contact us"
          text={[
            'Our team is on hand to help you with all your Gem Visa queries.'
          ]}
        />
      </div>

      <Section
        id="info"
        css={`
          z-index: 2;
        `}
      >
        <TileContainer threeQuarter>
          <Tile>
            <TileIcon>
              <SvgInline name="contact" />
            </TileIcon>
            <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK}>
              How to get in touch
            </Heading5>
            <p>
              If you have a question or need help, you can contact us through
              one of the options.
            </p>
          </Tile>
          <Tile>
            <List pointless>
              <ListItem>
                <strong>Within Australia call</strong> <Tel no="1300 552 079" />
              </ListItem>
              <ListItem>
                <strong>When overseas call</strong> <Tel no="+61 287 478 178" />
              </ListItem>
              <ListItem>
                Mon to Fri: 8:30am - 6pm (AEST)
                <br />
                Weekends: Closed
              </ListItem>
            </List>
          </Tile>
          <Tile>
            <List pointless>
              <ListItem>
                <strong>Fax</strong> 1300 369 905
              </ListItem>
              <ListItem>
                <strong>Latitude Gem Visa</strong> PO Box 1007
                <br />
                Melbourne VIC 3001
                <br />
              </ListItem>
              <ListItem>
                <strong>Contact us on</strong>{' '}
                <Link href="https://www.facebook.com/latitudefinancialservices/">
                  Facebook
                </Link>
              </ListItem>
            </List>
          </Tile>
        </TileContainer>

        <FlexContainer>
          <TileContainer half>
            <Tile purple>
              <TileIcon>
                <SvgInline name="lost-card" />
              </TileIcon>
              <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Lost or stolen card
              </Heading5>
              <p>Call customer Solutions immediately</p>
            </Tile>
            <Tile>
              <List pointless>
                <ListItem>
                  <strong>Within Australia call</strong>{' '}
                  <Tel no="1300 630 977" />
                </ListItem>
                <ListItem>
                  <strong>When overseas call</strong>{' '}
                  <Tel no="+61 1300 630 977" />
                  <br />
                </ListItem>
                <ListItem>Open 24 hours a day 365 days a year</ListItem>
              </List>
            </Tile>
          </TileContainer>

          <TileContainer half>
            <Tile purple>
              <TileIcon>
                <SvgInline name="lost-card" />
              </TileIcon>
              <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Declined transaction or suspect fraudulent activity
              </Heading5>
              <p>Call Customer Solutions immediately</p>
            </Tile>
            <Tile>
              <List pointless>
                <ListItem>
                  <strong>Within Australia call</strong>{' '}
                  <Tel no="1300 630 977" />
                </ListItem>
                <ListItem>
                  <strong>When overseas call</strong>{' '}
                  <Tel no="+61 1300 630 977" />
                  <br />
                </ListItem>
                <ListItem>Open 24 hours a day 365 days a year</ListItem>
              </List>
            </Tile>
          </TileContainer>
        </FlexContainer>

        <FlexContainer>
          <MediaQueryWrapper isVisibleLarge>
            <TileContainer quarter>
              <RetailerEnquiriesTile />
            </TileContainer>
            <TileContainer quarter>
              <DisputeTransactionTile />
            </TileContainer>
          </MediaQueryWrapper>
        </FlexContainer>

        <MediaQueryWrapper isVisibleSmallToMedium>
          <FlexContainer>
            <TileContainer>
              <RetailerEnquiriesTile />
            </TileContainer>
            <TileContainer>
              <DisputeTransactionTile />
            </TileContainer>
          </FlexContainer>
        </MediaQueryWrapper>

        <FlexContainer leftAlign>
          <TileContainer quarter>
            <Tile grey>
              <Heading5
                align={ALIGN.CENTER}
                color={COLOR.BLACK}
                className="expand"
              >
                Complaints
              </Heading5>
              <p>
                We know sometimes things don&apos;t go as well as they should
                and problems arise. If this happens to you please let us know so
                we can fix the problem.
              </p>
              <TileButtonLink
                href="https://latitudefs.zendesk.com/hc/en-au/articles/360020889237-Making-a-Complaint"
                button="tertiary"
              >
                Complaints &nbsp;
                <SvgInline name="external-link" />
              </TileButtonLink>
            </Tile>
          </TileContainer>
          <TileContainer quarter>
            <Tile grey>
              <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Hardship and financial difficulty
              </Heading5>
              <p>
                If you&apos;re having difficulties making repayments, it&apos;s
                best to get in touch as soon as possible - we have a dedicated
                Hardship Team to assist.
              </p>
              <TileButtonLink href="/hardship-care/" button="tertiary">
                Hardship Care
                <SvgInline name="external-link" />
              </TileButtonLink>
            </Tile>
          </TileContainer>
        </FlexContainer>
      </Section>
    </Box>
  </Layout>
);

export default ContactUs;

/**
 * Specific box components for "Retail enquiries" + "Dispute a transaction"
 */

const RetailerEnquiriesTile = () => {
  return (
    <Tile grey>
      <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK} className="expand">
        Retailer enquires
      </Heading5>
      <p>
        If you would like to offer interest free finance in your business,
        contact our Sales Team.
      </p>
      <TileButtonLink
        href="https://www.latitudefinancial.com.au/interest-free/business/"
        button="tertiary"
      >
        Become a partner
      </TileButtonLink>
    </Tile>
  );
};

const DisputeTransactionTile = () => {
  return (
    <Tile grey>
      <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK}>
        Dispute a transaction
      </Heading5>
      <p>
        Before contacting the merchant or Customer Solutions Team please take a
        moment to review the relevant FAQs for transaction disputes.
      </p>
      <TileButtonLink href="/faqs/" button="tertiary">
        View FAQs
      </TileButtonLink>
    </Tile>
  );
};
